$pageLayout-acceptCookie-link-color: rgba(5, 88, 255, 1);
$cookieSettings-h1: rgb(0, 117, 201);
$cookieSettings-text-color: $token-utility-black;
$pageLayout-acceptCookie-background: $token-color-grey-90;
$pageLayout-acceptCookie-text-color: $token-utility-white;
$pageLayout-acceptCookie-gridWrapper-max-width: none;
$pageLayout-acceptCookie-gridWrapper-margin-left: unset;
$pageLayout-acceptCookie-link-color: $token-utility-white;
$pageLayout-acceptCookie-link-decoration: underline;
$pageLayout-acceptCookie-button-bkg-color: unset;
$pageLayout-acceptCookie-margin-left: 0px;
$cookieSettings-label-span-font-weight: $token-font-weight-med;
$cookieSettings-cookieDescription-margin: $token-spacing-2 $token-spacing-0 $token-spacing-1 (-$token-spacing-8);
$cookieSettings-cookieDescription-font-size: inherit;
