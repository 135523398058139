@import "../custom-variables.scss";

// overwrite Shield Components variables here:
$brand-font-base: "Circular-USB", Arial;
$token-color-brand-primary: rgb(0, 117, 201);
$token-text-color-brand-primary: rgb(0, 117, 201);
$token-background-gradient-interaction: linear-gradient(0deg, rgba(0, 117, 201, 0.35), rgba(0, 117, 201, 0.35)), linear-gradient(180deg, #0075C9 0%, #0C2074 100%);
$token-background-focus-primary: rgb(0, 117, 201);
$token-background-focus-secondary: rgb(255, 255, 255);
$token-background-button-interaction: rgb(12, 32, 116);
$token-border-focus: rgb(0, 117, 201);
$token-shadow-drop: "3px 5px 11px 0px rgba(0, 0, 0, 0.4)";
$button-primary-background-color: rgb(0, 117, 201);
$button-secondary-border-color: rgb(0, 117, 201);
$token-text-color-tou: rgb(0, 113, 194);
$edp-button-loud-background-color: $token-color-brand-primary;

// overwrite CSS Modules variables here:
@import 'acceptTermsOfUse';
@import 'addUserPage';
@import 'blockArrayWrapper';
@import 'breadCrumb';
@import 'cookie';
@import 'cookiesBanner';
@import 'desktopNavBar';
@import 'displayViews';
@import 'fullPageBanner';
@import 'inputSearch';
@import 'markdownWithToc';
@import 'mobileNavBar';
@import 'pageFooter';
@import 'pageHeader';
@import 'pageLayout';
@import 'productDocumentPage';
@import 'regionPicker';
@import 'searchPage';
@import 'sideNavigationPanel';
@import 'stackItem';
@import 'showHide';
@import 'credentialsInput';

$usbTabs-background-color: rgb(70, 72, 70);

$hljs-background: rgb(70, 72, 70);
$hljs-color: $token-text-color-inverse-primary;

$hljs-comment-color: rgb(211, 211, 211) !important;
$hljs-subst-color: $token-text-color-inverse-primary;
$hljs-doctag-name-color: rgb(255, 182, 193) !important;
$hljs-keyword-attribute-color: rgb(255, 165, 0) !important;
$hljs-selector-tag-color: rgb(255, 165, 0) !important;
$hljs-attr-color: rgb(144, 238, 144) !important;
$hljs-built-bullet-code-addition-color: rgb(255, 182, 193) !important;
$hljs-literal-color: rgb(154, 193, 139);
$hljs-regexp-symbol-template-variable-link-selector-attr-pseudo-color: rgb(144, 238, 144) !important;
$hljs-type-string-selector-id-class-quote-template-tag-deletion-color: rgb(255, 165, 0) !important;
$hljs-title-section-color: rgb(144, 238, 144) !important;
$hljs-meta-color: rgb(255, 182, 193) !important;
$hljs-meta-keyword-color: rgb(255, 182, 193) !important;
$hljs-params-color: rgb(211, 211, 211) !important;
$hljs-function-color: $token-text-color-inverse-primary;
$hljs-number-color: rgb(255, 182, 193) !important;
$hsOverviewSection-bgcolor: $token-neutral-100 !important;

$dark-tooltip-background-color: $token-utility-white !important;
$dark-tooltip-content-text-color: $generic-grey-color-1 !important;

$sidebarFixedPositionWidth: 15.5rem;