$pageFooter-display: grid;
$pageFooter-block-margin: auto;
$pageFooter-width: max-content;
$pageFooter-text-align: center;
$pageFooter-block-spacing: $token-spacing-4;
$pageFooter-pipeline-border-right: $token-border-width-default solid $token-background-primary;
$pageFooter-pipeline-right-spacing: $token-spacing-4;
$pageFooter-gridWrapper-width: 100% !important;
$pageFooter-quicklinksListItem-display-mobile: inline-block;
$pageFooter-quicklinksListItem-p-padding-left: $token-spacing-0;
$pageFooter-quicklinksListItem-a-color: $token-text-color-brand-primary;
$pageFooter-quicklinksListItem-padding-mobile: $token-spacing-4 $token-spacing-0;
$pageFooter-quickLinkWithPipeMobile-border-right: $token-border-width-default solid $token-text-color-brand-primary;
$pageFooter-quickLinkColor-color: $token-text-color-brand-primary;
$pageFooter-disclaimerText-p-color: rgb(46, 46, 50);
$pageFooter-footerSection-padding: $token-spacing-7 $token-spacing-0;
$pageFooter-copyrightBlock-justify-self-tablet: start !important;
