$fullPageBanner-banner-box-shadow: none;
$fullPageBanner-banner-margin-top: $token-spacing-120px;
$fullPageBanner-banner-background: $token-utility-white;
$fullPageBanner-banner-padding: $token-spacing-4 $token-spacing-6;
$fullPageBanner-banner-border-radius: $token-spacing-2;
$fullPageBanner-banner-border: $token-border-width-default solid $token-color-grey-30;
$fullPageBanner-banner-width: $token-spacing-480px;
$fullPageBanner-content-padding: $token-spacing-0;
$fullPageBanner-btn1-btn2-font-size: $token-font-size-base;
$fullPageBanner-btn1-btn2-line-heigh: $token-spacing-6;
$fullPageBanner-btn1-background-color: $button-primary-background-color;
// $fullPageBanner-container-margin: $token-spacing-0 $token-spacing-0 $token-spacing-12;
$fullPageBanner-container-margin: $token-spacing-0;
// $fullPageBanner-container-margin-max-sm: $token-spacing-154px $token-spacing-0 $token-spacing-22;
$fullPageBanner-container-max-lg-padding: $token-spacing-0;
$fullPageBanner-container-max-lg-margin: $token-spacing-0 $token-spacing-0 $token-spacing-0;
$fullPageBanner-container-background: $token-background-primary;
$fullPageBanner-container-media-background: rgb(30, 30, 34);
$fullPageBanner-container-media-height: $token-spacing-560px;
// $fullPageBanner-contentBlock-max-lg-width: $token-spacing-375px;
$fullPageBanner-contentBlock-max-sm-width: auto;
$fullPageBanner-h2-font-size: $token-font-size-4xl;
$fullPageBanner-h2-max-sm-lg-font-size: $token-font-size-3xl;
$fullPageBanner-h2-font-weight: $token-font-weight-med;
$fullPageBanner-h2-line-height: $token-spacing-12;
$fullPageBanner-h2-max-sm-lg-line-height: $token-line-height-40px;
$fullPageBanner-p-margin: $token-spacing-0;
$fullPageBanner-p-font-size: $token-font-size-base;
$fullPageBanner-p-line-height: $token-spacing-6;
$fullPageBanner-backImg-min-width: unset;
$fullPageBanner-backImg-max-width: none;
$fullPageBanner-backImg-max-height: none;
$fullPageBanner-backImg-width: 100%;
$fullPageBanner-backImg-height: 100%;
$fullPageBanner-backImg-object-fit: cover;
$fullPageBanner-backImg-sm-screen-object-position: right (-$token-spacing-324px) top $token-spacing-0;
$fullPageBanner-backImg-screen-600-object-position: right (-$token-spacing-420px) top $token-spacing-0;
$fullPageBanner-backImg-screen-1024-object-position: right $token-spacing-272px top $token-spacing-0;
$fullPageBanner-backImg-screen-600-width: 100%;
// $fullPageBanner-bannerGrid-position: absolute;
// $fullPageBanner-bannerGrid-top: $token-spacing-0;
// $fullPageBanner-bannerGrid-screen-600-padding: $token-spacing-0 !important;
// $fullPageBanner-banner-max-sm-margin-top: (-$token-spacing-55px);
// $fullPageBanner-banner-max-lg-margin-top: (-$token-spacing-55px);
$fullPageBanner-bannerGrid-background: rgb(30, 30, 34);
@media #{$usb-min-1368} {
    .backImg {
      max-width: none !important;
      object-fit: cover !important;
    }   
}