@import '@/styles/theme/variables.scss';

.mainContainer {
    height: 60vh;
    min-height: auto;
    align-content: center;
}

.header {
    justify-content: center;
    display: flex;
    h1 {
        font-weight: $token-font-weight-med;
        font-size: $token-font-size-3xl;
        line-height: $token-line-height-40px;
        color: $token-text-color-brand-primary
    }
}

.shortText {
    text-align: center;
    p {
        margin-top: $token-rounded-base;
        font-weight: $token-font-weight-reg;
        font-size: $token-font-size-base;
        line-height: $token-line-height-4xl;
    }
}

.pageLink {
    display: flex;
    justify-content: center;
    margin-top: $token-spacing-8;
}